<template>
  <a-result
    status="success"
    title="提交成功!"
    sub-title="信息已经提交，请耐心等待"
  >
    <template #extra>
      <a-button key="console" type="primary">
        <a href="/my#/workorder" class="public-people">个人中心</a>
      </a-button>
      <a-button key="buy" @click="goHome">
        首页
      </a-button>
    </template>
  </a-result>
</template>
<script>
export default {
  data () {
    return {}
  },
  methods: {
    goHome () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
